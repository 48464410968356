import logo from './logo.svg';
import linkedinIcon from './linkedin-raw.svg';
import './App.css';
import image from './image_nature_2.webp';
import {Mail} from 'lucide-react';
import {Analytics} from "@vercel/analytics/react"

function App() {
  return (
    <div className="container" role="document">
      <header className="header" role="banner">
        <img
          src={logo}
          alt="Heroneco Logo"
          className="logo"
        />
      </header>
      <main className="main" role="main">
        <div className="content">
          <h1 className="heading">Coming soon...</h1>
          <p className="subheading">
            Big things are happening!😊
            Our sustainable travel platform is about to launch and set to redefine
            responsible travel🌏
          </p>
          {/*<button className="button">Contact us for more info</button>*/}
        </div>
        <div className="image-container">
          <img
            src={image}
            alt="Nature Scene"
            className="image"
          />
        </div>
      </main>
      <footer className="footer" role="contentinfo">
        <div className="footer-container">
          <p className="footer-text">&copy; 2024 Heroneco. All rights reserved.</p>
          <nav className="social-links" aria-label="Social media links">
            <a href="https://www.linkedin.com/company/heroneco/"
               target="_blank"
               rel="noopener noreferrer"
               style={{color: '#fff'}}
               aria-label="Visit Heroneco LinkedIn page">
              <img
                src={linkedinIcon}
                alt="LinkedIn"
                className="image"
              />
            </a>
            <a href="mailto:contact@heroneco.com"
               target="_blank"
               aria-label="Send email to Heroneco"
               style={{color: '#fff'}}>
              <Mail size={24} aria-hidden="true"/>
            </a>
          </nav>
        </div>
      </footer>
      <Analytics/>
    </div>
  )
    ;
}

export default App;
